import { fromPairs, compact, every, get, sortBy, startsWith, uniq, flow, uniqBy } from 'lodash'
import { take, all, fork, select } from 'redux-saga/effects'
import { matchPath } from 'react-router'
import { PATHS } from '@utils/router'
import { getRangeInDays } from '@utils/time-utils'
import {
	LOAD_TYPE,
	OPERATION_STATUSES,
	EXTENDED_SERVICES,
	EXTENDED_SERVICES_SUBTYPE,
	ORDER_STATUSES,
	PAYMENT_STATUSES,
	PRODUCT_TYPES,
} from '@libs/foma/types'
import {
	CUSTOMER_FORM,
	PASSENGERS_FORM,
	CREDIT_CARD_FORM,
} from '@store/state/uiState/forms/types'
import {
	customerSelectors,
	passengerSelectors,
	creditCardSelectors,
	invalidFieldsSelector,
	firstInvalidFieldSelector,
} from '@store/state/uiState/forms/selectors'
import {
	REDIRECT_TO_PARTNER,
	LOCATION_CHANGE,
	SET_OFFER_OP_STATUS,
	SET_FORM_FIELD_VALUE,
	ADD_UPSALE,
	REMOVE_UPSALE,
	CHANGE_CONSIST,
	CLICK_ACCEPT_TERMS,
	SELECT_PAYMENT_METHOD,
	BUY,
	BOOK,
	SET_BOOK_STATUS,
	SET_CHANGE_CONSIST_STATUS,
	RECONFIGURE_OFFER,
	ADD_LOADING_EVENT,
	SET_OFFER_RECONFIGURE_STATUS,
	ON_ELEMENT_CLICK,
	REMOVE_LOADING_EVENT,
	HANDLE_BLUR_EVT,
	GET_OFFER_RESOLVED_WITH_STATUS,
} from '@store/state/types'
import { checkBookSelector } from '@store/state/selectors'
import {
	locationQuerySelector,
	byCurrentOfferId,
	byCurrentSearchQuery,
	routerDataSelector,
	offerReconfigureStatusSelector,
	currentSearchQuerySelector,
	channelSelector,
} from '@store/state/appState/selectors'
import {
	orderFromOfferIdSelector,
	flightFaresFromOfferSelector,
	offerByIdSelector,
	flightConsistFromOfferSelector,
	routeInfoSelector,
	outboundFlightFromOfferSelector,
	packageCustomInsFaresFromOfferSelector,
	travelInsFaresFromOfferSelector,
	inabilityToTravelInsFaresFromOfferSelector,
	checkInFaresFromOfferSelector,
	prioritySupportFaresFromOfferSelector,
	smsInfoFaresFromOfferSelector,
	enabledFaresSelector,
	isCardholderRequiredSelector,
} from '@store/state/domainData/selectors'
import segmentsCountSelector from 'components/@organisms/CaOrderTicketsInfo/selectors/segmentsCount'
import dominantAirlineSelector from 'components/@organisms/CaOrderTicketsInfo/selectors/dominantAirline'
import commonPaymentDetailsSelector from 'components/@molecules/CaPaymentDetails/selectors/detailsCommon'
import { getSimpleActionToEvent, getFormFillActionToEvent } from '../../helpers/actionListenerCreators'

import flightFaresVariantsLoadedListener from './flightFaresVariantsLoaded'
import startToFillpanListener from './startToFillPan'


const makePriceGroupGetter = (priceDelimiter) => (price) => {
	const coeff = Math.floor(price / priceDelimiter)
	const groupStart = priceDelimiter * coeff
	const groupEnd = groupStart + priceDelimiter - 1
	return `${groupStart || 1}-${groupEnd}`
}
const priceGroupSelector = (state) => {
	const { totalPrice } = commonPaymentDetailsSelector(state)
	const PRICE_GROUP_DELIMITER = 2500
	const getPriceGroup = makePriceGroupGetter(PRICE_GROUP_DELIMITER)
	return getPriceGroup(totalPrice)
}

const {
	SMS,
	INSURANCE,
} = EXTENDED_SERVICES_SUBTYPE

const ecommerceData = (serpParams, fares, key, actionField) => {
	const iataCodes = serpParams.split('/', 3).slice(0, 2)
	const sortedFares = sortBy(
		Object.entries(fares).map(([ _, fare ]) => fare).filter((fare) => startsWith(fare.service, 'flight')),
		(fare) => {
			let [ _, ...legs ] = fare.service.split(' ')
			return legs.map((leg) => leg.split('-')[2].slice(0, 8)).sort()[0]
		}
	)
	const products = sortedFares.map((fare, idx) => {
		let [ kind, ...legs ] = fare.service.split(' ')
		kind =	kind.split(':')[1]
		let segmentKind = sortedFares.length > 1 || legs.length > 1 ? 'RT' : 'OW'
		let crossKind = sortedFares.length > 1 ? 'cross' : 'non-cross'
		let fareIataCodes = idx === 0 ? iataCodes : iataCodes.reverse()

		return {
			id: fare.service,
			name: fareIataCodes.join('-'),
			price: fare.price.buyer,
			brand: fare.supplier,
			category: `Flight/${kind}/${segmentKind}/${crossKind}`,
			variant: legs.map((leg) => leg.split('-')[2].slice(2, 8)).join('/'),
			quantity: 1,
			dimension1: uniq(legs.map((leg) => leg.split('-')[0])).join('/'),
			metric1: fare.details.baggage.checked.included,
			metric2: fare.details.baggage.checked.weight,
		}
	})
	return {
		ecommerce: {
			currencyCode: 'RUB',
			[key]: {
				actionField,
				products: uniqBy(products, 'name'),
			},
		},
	}
}

const redirectToPartnerConfig = {
	eventName: 'CA_REDIRECT_TO_PARTNER',
	pattern: REDIRECT_TO_PARTNER,
}
const checkoutOpenedConfig = {
	eventName: 'CA_CHECKOUT_OPENED',
	pattern: ({ type, payload }) => (
		type === LOCATION_CHANGE
		&& payload.action !== 'REPLACE'
		&& Boolean(matchPath(payload.location.pathname, PATHS.CHECKOUT))
		&& !payload.location.search.includes('order_id')
		&& !payload.location.search.includes('book_by_redirect')
	),
}
const isCheckoutPrevLocationSelector = (state) => {
	const { prevLocation } = routerDataSelector(state)
	return Boolean(matchPath(prevLocation.pathname, PATHS.CHECKOUT))
}
const transitionToSerpConfig = {
	eventName: 'CA_GO_TO_SERP_FROM_CHECKOUT',
	pattern: ({ type, payload }) => (
		type === LOCATION_CHANGE
		&& payload.action !== 'REPLACE'
		&& Boolean(matchPath(payload.location.pathname, PATHS.SERP))
	),
	checkStateConditions: function* () {
		const isCheckoutPrev = yield select(isCheckoutPrevLocationSelector)
		return isCheckoutPrev
	},
}
const transitionToIndexConfig = {
	eventName: 'CA_GO_TO_INDEX_FROM_CHECKOUT',
	pattern: ({ type, payload }) => (
		type === LOCATION_CHANGE
		&& payload.action !== 'REPLACE'
		&& Boolean(matchPath(payload.location.pathname, {
			path: PATHS.INDEX,
			exact: true,
		}))
	),
	checkStateConditions: function* () {
		const isCheckoutPrev = yield select(isCheckoutPrevLocationSelector)
		return isCheckoutPrev
	},
}

const getFareAbTestEntity = (fares, fn, path) => uniq(compact(
	Object.values(fares).map((fare) => fn(get(fare, path, {})))
)).join('|')
const getFareAbTestName = (fares) => getFareAbTestEntity(fares, Object.keys, 'price.labels.ab')
const getFareAbTestBranch = (fares) => getFareAbTestEntity(fares, Object.values, 'price.labels.ab')
const faresAbTestPayload = (faresOpts) => {
	const result = {}
	faresOpts.forEach(([ fareKey, fares ]) => {
		const name = getFareAbTestName(fares)
		if (name) result[`${fareKey}_ab_name`] = name
		const branch = getFareAbTestBranch(fares)
		if (branch) result[`${fareKey}_ab_branch`] = branch
	})
	return result
}

const getFareDynamicPoint = (fares) => getFareAbTestEntity(fares, Object.values, 'price.labels.dynamic.point')
const faresDynamicPayload = (faresOpts) => {
	const result = {}
	faresOpts.forEach(([ fareKey, fares ]) => {
		const val = getFareDynamicPoint(fares)
		if (val) result[`${fareKey}_dynamic`] = val
	})
	return result
}

const upsalesPayload = (faresOpts) => fromPairs(
	faresOpts.map(([ fareKey, fares ]) => [ fareKey, Object.keys(fares).length ? 'yes' : 'no' ])
)

const amplitudeUpsalesPayload = function* (useEnabledFares = false, step = 'order') {
	let smsInfoFares, medicalInsFares, travelInsFares, inabilityToTravelInsFares, checkInFares, prioritySupportFares
	if (useEnabledFares) {
		smsInfoFares = yield select(byCurrentOfferId(enabledFaresSelector(smsInfoFaresFromOfferSelector)))
		medicalInsFares = yield select(byCurrentOfferId(enabledFaresSelector(travelInsFaresFromOfferSelector)))
		travelInsFares = yield select(byCurrentOfferId(enabledFaresSelector(packageCustomInsFaresFromOfferSelector)))
		inabilityToTravelInsFares = yield select(
			byCurrentOfferId(enabledFaresSelector(inabilityToTravelInsFaresFromOfferSelector))
		)
		checkInFares = yield select(byCurrentOfferId(enabledFaresSelector(checkInFaresFromOfferSelector)))
		prioritySupportFares = yield select(byCurrentOfferId(enabledFaresSelector(prioritySupportFaresFromOfferSelector)))
	}
	else {
		smsInfoFares = yield select(byCurrentOfferId(smsInfoFaresFromOfferSelector))
		medicalInsFares = yield select(byCurrentOfferId(travelInsFaresFromOfferSelector))
		travelInsFares = yield select(byCurrentOfferId(packageCustomInsFaresFromOfferSelector))
		inabilityToTravelInsFares = yield select(byCurrentOfferId(inabilityToTravelInsFaresFromOfferSelector))
		checkInFares = yield select(byCurrentOfferId(checkInFaresFromOfferSelector))
		prioritySupportFares = yield select(byCurrentOfferId(prioritySupportFaresFromOfferSelector))
	}
	return upsalesPayload([
		[ `upsale_${step}_sms`, smsInfoFares ],
		[ `upsale_${step}_reg`, checkInFares ],
		[ `upsale_${step}_prioritySupport`, prioritySupportFares ],
		[ `upsale_${step}_3cov`, travelInsFares ],
		[ `upsale_${step}_medical`, medicalInsFares ],
		[ `upsale_${step}_inabilityToTravel`, inabilityToTravelInsFares ],
	])
}
const amplitudeAbTestPayload = function* (useEnabledFares = false, step = 'order') {
	let flightFares, smsInfoFares, medicalInsFares, travelInsFares, inabilityToTravelInsFares,
		checkInFares, prioritySupportFares
	if (useEnabledFares) {
		flightFares = yield select(byCurrentOfferId(enabledFaresSelector(flightFaresFromOfferSelector)))
		smsInfoFares = yield select(byCurrentOfferId(enabledFaresSelector(smsInfoFaresFromOfferSelector)))
		medicalInsFares = yield select(byCurrentOfferId(enabledFaresSelector(travelInsFaresFromOfferSelector)))
		travelInsFares = yield select(byCurrentOfferId(enabledFaresSelector(packageCustomInsFaresFromOfferSelector)))
		inabilityToTravelInsFares = yield select(
			byCurrentOfferId(enabledFaresSelector(inabilityToTravelInsFaresFromOfferSelector))
		)
		checkInFares = yield select(byCurrentOfferId(enabledFaresSelector(checkInFaresFromOfferSelector)))
		prioritySupportFares = yield select(byCurrentOfferId(enabledFaresSelector(prioritySupportFaresFromOfferSelector)))
	}
	else {
		flightFares = yield select(byCurrentOfferId(flightFaresFromOfferSelector))
		smsInfoFares = yield select(byCurrentOfferId(smsInfoFaresFromOfferSelector))
		medicalInsFares = yield select(byCurrentOfferId(travelInsFaresFromOfferSelector))
		travelInsFares = yield select(byCurrentOfferId(packageCustomInsFaresFromOfferSelector))
		inabilityToTravelInsFares = yield select(byCurrentOfferId(inabilityToTravelInsFaresFromOfferSelector))
		checkInFares = yield select(byCurrentOfferId(checkInFaresFromOfferSelector))
		prioritySupportFares = yield select(byCurrentOfferId(prioritySupportFaresFromOfferSelector))
	}
	return faresAbTestPayload([
		[ `${step}_flight`, flightFares ],
		[ `upsale_${step}_sms`, smsInfoFares ],
		[ `upsale_${step}_reg`, checkInFares ],
		[ `upsale_${step}_prioritySupport`, prioritySupportFares ],
		[ `upsale_${step}_3cov`, travelInsFares ],
		[ `upsale_${step}_medical`, medicalInsFares ],
		[ `upsale_${step}_inabilityToTravel`, inabilityToTravelInsFares ],
	])
}
const amplitudeDynamicPayload = function* (useEnabledFares = false, step = 'order') {
	let flightFares
	if (useEnabledFares) {
		flightFares = yield select(byCurrentOfferId(enabledFaresSelector(flightFaresFromOfferSelector)))
	}
	else {
		flightFares = yield select(byCurrentOfferId(flightFaresFromOfferSelector))
	}
	return faresDynamicPayload([
		[ `${step}_flight`, flightFares ],
	])
}
const amplitudeOfferPayload = function* (useEnabledFares = false, step = 'order') {
	const isCardHolderRequired = yield select(byCurrentOfferId(isCardholderRequiredSelector))
	const flightFares = yield select(byCurrentOfferId(enabledFaresSelector(flightFaresFromOfferSelector)))

	const flightSuppliers = uniq(Object.entries(flightFares).map(([ _, flightFare ]) => (
		flightFare.supplier
	)).filter(Boolean))
	const { outboundSegmentsCount, inboundSegmentsCount } = yield select(segmentsCountSelector)
	const isRT = yield select(flow(
		byCurrentSearchQuery(routeInfoSelector),
		(routeInfo) => (routeInfo.length > 1)
	))
	const dominantAirline = yield select(dominantAirlineSelector)
	const totalPriceGroup = yield select(priceGroupSelector)
	const offer = yield select(byCurrentOfferId(offerByIdSelector))
	const [ [ flightProductType ] ] = offer.products.filter(([ type ]) => (
		PRODUCT_TYPES.FLIGHTS.includes(type)
	))
	const [ { depart } ] = yield select(byCurrentOfferId(outboundFlightFromOfferSelector))
	const daysBeforeFlight = getRangeInDays(new Date(), new Date(depart.time))

	const upsales = yield amplitudeUpsalesPayload(useEnabledFares, step)
	const abTestPayload = yield amplitudeAbTestPayload(useEnabledFares, step)
	const dynamicPayload = yield amplitudeDynamicPayload(useEnabledFares, step)
	return {
		...upsales,
		...abTestPayload,
		...dynamicPayload,
		flightSuppliers,
		outboundSegmentsCount,
		inboundSegmentsCount,
		rtOrOw: isRT ? 'RT' : 'OW',
		dominantAirline,
		totalPriceGroup,
		flightProductType,
		daysBeforeFlight,
		'card_holder_required': isCardHolderRequired,
	}
}

const offerSuccessStatuses = [ OPERATION_STATUSES.AVAIL, OPERATION_STATUSES.SUCCESS ]
const getLoadOfferSuccessConfig = (eventName, payloadType) => ({
	eventName,
	pattern: ({ type, payload }) => (
		type === REMOVE_LOADING_EVENT
		&& payload.type === payloadType
	),
	checkStateConditions: function* () {
		const offerOpStatus = yield select((state) => state.app.appState.requestStatuses.offerOpStatus)
		if (offerOpStatus === OPERATION_STATUSES.LOADING) {
			const { payload: status } = yield take(SET_OFFER_OP_STATUS)
			if (!offerSuccessStatuses.includes(status)) return false
		}
		const {
			order_id: orderId,
			book_by_redirect: isBookByRedirect,
		} = yield select(locationQuerySelector)
		return !orderId && !isBookByRedirect
	},
	getEventPayload: function* () { return yield amplitudeOfferPayload() },
})

const loadOfferSuccessConfig = getLoadOfferSuccessConfig('CA_LOAD_OFFER_SUCCESS', LOAD_TYPE.OFFER)
const checkOfferSuccessConfig = getLoadOfferSuccessConfig('CA_CHECK_OFFER_SUCCESS', LOAD_TYPE.CHECK_OFFER)
const bookOrderConfig = {
	eventName: 'CA_BOOK_ORDER_BEGIN',
	pattern: BOOK,
	getEventPayload: function* () {
		const payload = yield amplitudeOfferPayload(true)
		const email = yield select(customerSelectors.emailSelector)
		return { ...payload, email }
	},
}
const ecChechoutStartConfig = {
	eventName: 'EC_CHECKOUT',
	pattern: ({ type, payload }) => (
		type === SET_OFFER_OP_STATUS
			&& offerSuccessStatuses.includes(payload)
	),
	checkStateConditions: function* () {
		const {
			order_id: orderId,
			book_by_redirect: isBookByRedirect,
		} = yield select(locationQuerySelector)
		return !orderId && !isBookByRedirect
	},
	getEventPayload: function* () {
		const fares = yield select(byCurrentOfferId(flightFaresFromOfferSelector))
		const serpParams = yield select(currentSearchQuerySelector)
		return ecommerceData(serpParams, fares, 'checkout', { step: 1 })
	},
}
const transitOfferOpStatuses = [
	OPERATION_STATUSES.LOADING,
	OPERATION_STATUSES.NONE,
]
const loadOfferFailureConfig = {
	eventName: 'CA_LOAD_OFFER_FAILURE',
	pattern: ({ type, payload }) => (
		type === SET_OFFER_OP_STATUS
		&& !transitOfferOpStatuses.includes(payload)
		&& !offerSuccessStatuses.includes(payload)
	),
	checkStateConditions: function* () {
		const {
			order_id: orderId,
			book_by_redirect: isBookByRedirect,
		} = yield select(locationQuerySelector)
		return !orderId && !isBookByRedirect
	},
	getEventPayload: ({ payload: status }) => ({
		'offer_status': status,
	}),
}

const addSmsInfoCheckboxConfig = {
	eventName: 'CA_ADD_SMS_INFO_CHECKBOX',
	pattern: ({ type, meta }) => (
		type === ADD_UPSALE
		&& meta.serviceType === SMS.INFO
		&& meta.source === 'customer-form-checkbox'
	),
}
const addSmsInfoAdditionalServiceBlockConfig = {
	eventName: 'CA_ADD_SMS_INFO_ADDITIONAL_SERVICE_BLOCK',
	pattern: ({ type, meta }) => (
		type === ADD_UPSALE
		&& meta.serviceType === SMS.INFO
		&& meta.source === 'additional-service-block'
	),
}
const addSmsInfoUpsaleDialogConfig = {
	eventName: 'CA_ADD_SMS_INFO_UPSALE_DIALOG',
	pattern: ({ type, meta }) => (
		type === ADD_UPSALE
		&& meta.serviceType === SMS.INFO
		&& meta.source === 'upsale-dialog'
	),
}
const removeSmsInfoUpsaleDialogConfig = {
	eventName: 'CA_REMOVE_SMS_INFO_UPSALE_DIALOG',
	pattern: ({ type, meta }) => (
		type === REMOVE_UPSALE
		&& meta.serviceType === SMS.INFO
		&& meta.source === 'upsale-dialog'
	),
}
const removeSmsInfoCheckboxConfig = {
	eventName: 'CA_REMOVE_SMS_INFO_CHECKBOX',
	pattern: ({ type, meta }) => (
		type === REMOVE_UPSALE
		&& meta.serviceType === SMS.INFO
		&& meta.source === 'customer-form-checkbox'
	),
}
const removeSmsInfoAdditionalServiceBlockConfig = {
	eventName: 'CA_REMOVE_SMS_INFO_ADDITIONAL_SERVICE_BLOCK',
	pattern: ({ type, meta }) => (
		type === REMOVE_UPSALE
		&& meta.serviceType === SMS.INFO
		&& meta.source === 'additional-service-block'
	),
}
const addPackageCustomInsuranceCheckboxConfig = {
	eventName: 'CA_ADD_PACKAGE_CUSTOM_INSURANCE_CHECKBOX',
	pattern: ({ type, meta }) => (
		type === ADD_UPSALE
		&& meta.serviceType === INSURANCE.PACKAGE_CUSTOM
		&& meta.source === 'checkbox'
	),
}
const addInsuranceRadioBtnConfig = {
	eventName: 'CA_ADD_INSURANCE_RADIOBTN',
	pattern: ({ type, meta }) => (
		type === ADD_UPSALE
		&& [ INSURANCE.PACKAGE_CUSTOM, INSURANCE.TRAVEL, INSURANCE.INABILITY_TO_TRAVEL ].includes(meta.serviceType)
		&& meta.source === 'radiobtn'
	),
}
const addPackageCustomInsuranceUpsaleDialogConfig = {
	eventName: 'CA_ADD_PACKAGE_CUSTOM_INSURANCE_UPSALE_DIALOG',
	pattern: ({ type, meta }) => (
		type === ADD_UPSALE
		&& meta.serviceType === INSURANCE.PACKAGE_CUSTOM
		&& meta.source === 'upsale-dialog'
	),
}
const removePackageCustomInsuranceUpsaleDialogConfig = {
	eventName: 'CA_REMOVE_PACKAGE_CUSTOM_INSURANCE_UPSALE_DIALOG',
	pattern: ({ type, meta }) => (
		type === REMOVE_UPSALE
		&& meta.serviceType === INSURANCE.PACKAGE_CUSTOM
		&& meta.source === 'upsale-dialog'
	),
}
const addTravelInsuranceCheckboxConfig = {
	eventName: 'CA_ADD_TRAVEL_INSURANCE_CHECKBOX',
	pattern: ({ type, meta }) => (
		type === ADD_UPSALE
		&& meta.serviceType === INSURANCE.TRAVEL
		&& meta.source === 'checkbox'
	),
}
const addInabilityToTravelInsuranceCheckboxConfig = {
	eventName: 'CA_ADD_INABILITY_TO_TRAVEL_INSURANCE_CHECKBOX',
	pattern: ({ type, meta }) => (
		type === ADD_UPSALE
		&& meta.serviceType === INSURANCE.INABILITY_TO_TRAVEL
		&& meta.source === 'checkbox'
	),
}
const removePackageCustomInsuranceCheckboxConfig = {
	eventName: 'CA_REMOVE_PACKAGE_CUSTOM_INSURANCE_CHECKBOX',
	pattern: ({ type, meta }) => (
		type === REMOVE_UPSALE
		&& meta.serviceType === INSURANCE.PACKAGE_CUSTOM
		&& meta.source === 'checkbox'
	),
}
const removeTravelInsuranceCheckboxConfig = {
	eventName: 'CA_REMOVE_TRAVEL_INSURANCE_CHECKBOX',
	pattern: ({ type, meta }) => (
		type === REMOVE_UPSALE
		&& meta.serviceType === INSURANCE.TRAVEL
		&& meta.source === 'checkbox'
	),
}
const removeInabilityToTravelInsuranceCheckboxConfig = {
	eventName: 'CA_REMOVE_INABILITY_TO_TRAVEL_INSURANCE_CHECKBOX',
	pattern: ({ type, meta }) => (
		type === REMOVE_UPSALE
		&& meta.serviceType === INSURANCE.INABILITY_TO_TRAVEL
		&& meta.source === 'checkbox'
	),
}
const removeInsuranceRadbioBtnConfig = {
	eventName: 'CA_REMOVE_INSURANCE_RADIOBTN',
	pattern: ({ type, meta }) => (
		type === REMOVE_UPSALE
		&& [ INSURANCE.PACKAGE_CUSTOM, INSURANCE.TRAVEL, INSURANCE.INABILITY_TO_TRAVEL ].includes(meta.serviceType)
		&& meta.source === 'radiobtn'
	),
}
const addFlightOnlineCheckinConfig = {
	eventName: 'CA_ADD_FLIGHT_ONLINE_CHECKIN',
	pattern: ({ type, meta }) => (
		type === ADD_UPSALE
		&& meta.serviceType === EXTENDED_SERVICES.CHECK_IN
	),
}
const removeFlightOnlineCheckinConfig = {
	eventName: 'CA_REMOVE_FLIGHT_ONLINE_CHECKIN',
	pattern: ({ type, meta }) => (
		type === REMOVE_UPSALE
		&& meta.serviceType === EXTENDED_SERVICES.CHECK_IN
	),
}
const addPrioritySupportConfig = {
	eventName: 'CA_ADD_PRIORITY_SUPPORT',
	pattern: ({ type, meta }) => (
		type === ADD_UPSALE
		&& meta.serviceType === EXTENDED_SERVICES.PRIORITY_SUPPORT
	),
}
const removePrioritySupportConfig = {
	eventName: 'CA_REMOVE_PRIORITY_SUPPORT',
	pattern: ({ type, meta }) => (
		type === REMOVE_UPSALE
		&& meta.serviceType === EXTENDED_SERVICES.PRIORITY_SUPPORT
	),
}
const changeConsistOnCheckoutConfig = {
	eventName: 'CA_CHANGE_CONSIST_ON_CHECKOUT',
	pattern: CHANGE_CONSIST,
}
const acceptTermsClickConfig = {
	eventName: 'CA_ACCEPT_TERMS_CLICK',
	pattern: CLICK_ACCEPT_TERMS,
}
const paymentMethodConfig = {
	eventName: 'CA_SELECT_PAYMENT_METHOD',
	pattern: SELECT_PAYMENT_METHOD,
}
const buyBtnClickConfig = {
	eventName: 'CA_BUY_BTN_CLICK',
	pattern: ({ type, meta }) => (
		type === BUY
		&& meta.isInitial
	),
}
const ecChechoutBuyStepConfig = {
	eventName: 'EC_CHECKOUT_BUY_STEP',
	pattern: ({ type, meta }) => (
		type === BUY
			&& meta.isInitial
	),
	checkStateConditions: function* () {
		const invalidFields = yield select(firstInvalidFieldSelector)
		return !invalidFields
	},
	getEventPayload: function* () {
		const fares = yield select(byCurrentOfferId(flightFaresFromOfferSelector))
		const serpParams = yield select(currentSearchQuerySelector)
		return ecommerceData(serpParams, fares, 'checkout', { step: 2 })
	},
}
const ecChechoutBookStepConfig = {
	eventName: 'EC_CHECKOUT_BOOK_STEP',
	pattern: BOOK,
	getEventPayload: function* () {
		const fares = yield select(byCurrentOfferId(flightFaresFromOfferSelector))
		const serpParams = yield select(currentSearchQuerySelector)
		return ecommerceData(serpParams, fares, 'checkout', { step: 3 })
	},
}
const transitBookOpStatuses = [
	OPERATION_STATUSES.LOADING,
	OPERATION_STATUSES.NONE,
	OPERATION_STATUSES.PAYMENT_3DS_REQUIRED,
]
const bookSuccessConfig = {
	eventName: 'CA_BOOK_SUCCESS',
	pattern: ({ type, payload }) => (
		type === SET_BOOK_STATUS
		&& !transitBookOpStatuses.includes(payload)
	),
	checkStateConditions: function* () {
		const { isSuccess } = yield select(checkBookSelector)
		return isSuccess
	},
	getEventPayload: function* () {
		const order = yield select(byCurrentOfferId(orderFromOfferIdSelector))
		const upsales = yield amplitudeUpsalesPayload(true, 'paid')
		const abTestPayload = yield amplitudeAbTestPayload(true, 'paid')
		const dynamicPayload = yield amplitudeDynamicPayload(true, 'paid')
		const isCardHolderRequired = yield select(byCurrentOfferId(isCardholderRequiredSelector))

		return {
			...upsales,
			...abTestPayload,
			...dynamicPayload,
			orderSupportId: order && order['support_id'],
			'card_holder_required': isCardHolderRequired,
		}
	},
}
const ecPurchaseConfig = {
	eventName: 'EC_PURCHASE',
	pattern: ({ type, payload }) => (
		type === SET_BOOK_STATUS
			&& !transitBookOpStatuses.includes(payload)
	),
	checkStateConditions: function* () {
		const { isSuccess } = yield select(checkBookSelector)
		return isSuccess
	},
	getEventPayload: function* () {
		const fares = yield select(byCurrentOfferId(flightFaresFromOfferSelector))
		const serpParams = yield select(currentSearchQuerySelector)
		const order = yield select(byCurrentOfferId(orderFromOfferIdSelector))
		const offer = yield select(byCurrentOfferId(offerByIdSelector))
		const channel = yield select(channelSelector)
		if (order) {
			const actionField = {
				id: order['support_id'],
				affiliation: channel,
				revenue: offer.price,
			}
			return ecommerceData(serpParams, fares, 'purchase', actionField)
		}
		else {
			return {}
		}
	},
}
const createOrderFailedConfig = {
	eventName: 'CA_BOOK_FAILED',
	pattern: ({ type, payload }) => (
		type === SET_BOOK_STATUS
		  && payload === OPERATION_STATUSES.UNAVAIL
	),
	getEventPayload: ({ payload: bookStatus }) => ({
		'book_status': bookStatus,
	}),
}
const bookFailedConfig = {
	eventName: 'CA_BOOK_FAILED',
	pattern: ({ type, payload }) => (
		type === SET_BOOK_STATUS
		&& !transitBookOpStatuses.includes(payload)
	),
	checkStateConditions: function* () {
		const { isFailed, isCriticalFailed } = yield select(checkBookSelector)
		return isFailed || isCriticalFailed
	},
	getEventPayload: ({ payload: bookStatus }) => ({
		bookFailedStatus: bookStatus,
		'book_status': bookStatus,
	}),
}
const bookFailedNotCriticalConfig = {
	eventName: 'CA_BOOK_FAILED_NOT_CRITICAL',
	pattern: ({ type, payload }) => (
		type === SET_BOOK_STATUS
		&& !transitBookOpStatuses.includes(payload)
	),
	checkStateConditions: function* () {
		const { isFailed } = yield select(checkBookSelector)
		return isFailed
	},
	getEventPayload: ({ payload: bookStatus }) => ({
		'book_status': bookStatus,
	}),
}
const redirectTo3DSConfig = {
	eventName: 'CA_REDIRECT_TO_3DS',
	pattern: ({ type, payload }) => (
		type === SET_BOOK_STATUS
		&& payload === OPERATION_STATUSES.PAYMENT_3DS_REQUIRED
	),
}
const checkoutOpenedFrom3dsConfig = {
	eventName: 'CA_CHECKOUT_OPENED_FROM_3DS',
	pattern: ({ type, payload }) => (
		type === LOCATION_CHANGE
		&& payload.action !== 'REPLACE'
		&& Boolean(matchPath(payload.location.pathname, PATHS.CHECKOUT))
		&& payload.location.search.includes('book_by_redirect')
	),
}
const priceChangedConfig = {
	eventName: 'CA_PRICE_CHANGED',
	pattern: ({ type, payload }) => (
		type === SET_BOOK_STATUS
		&& payload === OPERATION_STATUSES.PRICE_CHANGED
	),
	checkStateConditions: function* () {
		const order = yield select(byCurrentOfferId(orderFromOfferIdSelector))
		const orderStatus = order && order.status
		const paymentStatus = order && order['payment_status']
		return orderStatus === ORDER_STATUSES.AVAIL && paymentStatus === PAYMENT_STATUSES.UNPAID
	},
}
const normalChangeConsistStatuses = [
	OPERATION_STATUSES.NONE,
	OPERATION_STATUSES.AVAIL,
	OPERATION_STATUSES.LOADING,
]
const changeConsistOnCheckoutFailedConfig = {
	eventName: 'CA_CHANGE_CONSIST_ON_CHECKOUT_FAILED',
	pattern: ({ type, payload }) => (
		type === SET_CHANGE_CONSIST_STATUS
		&& !normalChangeConsistStatuses.includes(payload)
	),
}
const changeConsistOnCheckoutSuccessConfig = {
	eventName: 'CA_CHANGE_CONSIST_ON_CHECKOUT_SUCCESS',
	pattern: ({ type, payload }) => (
		type === SET_CHANGE_CONSIST_STATUS
		&& payload === OPERATION_STATUSES.AVAIL
	),
	getEventPayload: function* () {
		const consist = yield select(byCurrentOfferId(flightConsistFromOfferSelector))
		return {
			consist,
			paxCount: consist.reduce((sum, count) => sum + count, 0),
		}
	},
}
const changeFlightVariantConfig = {
	eventName: 'CA_CHANGE_FLIGHT_VARIANT_CLICK',
	pattern: ({ type, payload }) => (
		type === RECONFIGURE_OFFER
		&& payload.serviceType === EXTENDED_SERVICES.TRANSFER_VARIANTS
	),
}
const getChangeReconfigureStatusConfig = (eventName, serviceType, status) => {
	let prevStatus = OPERATION_STATUSES.NONE
	return {
		eventName,
		pattern: status === OPERATION_STATUSES.LOADING
			? ADD_LOADING_EVENT
			: [ RECONFIGURE_OFFER, SET_OFFER_RECONFIGURE_STATUS ],
		checkStateConditions: function* () {
			const currentStatus = yield select(offerReconfigureStatusSelector, serviceType)
			if (currentStatus === prevStatus) return false
			prevStatus = currentStatus
			return status === currentStatus
		},
	}
}
const changeFlightVariantLoadingConfig = getChangeReconfigureStatusConfig(
	'CA_CHANGE_FLIGHT_VARIANT_LOADING',
	EXTENDED_SERVICES.TRANSFER_VARIANTS,
	OPERATION_STATUSES.LOADING
)
const changeFlightVariantSuccessConfig = getChangeReconfigureStatusConfig(
	'CA_CHANGE_FLIGHT_VARIANT_SUCCESS',
	EXTENDED_SERVICES.TRANSFER_VARIANTS,
	OPERATION_STATUSES.AVAIL
)
const changeSmsInfoLoadingConfig = getChangeReconfigureStatusConfig(
	'CA_CHANGE_SMS_INFO_LOADING',
	SMS.INFO,
	OPERATION_STATUSES.LOADING
)
const changeSmsInfoSuccessConfig = getChangeReconfigureStatusConfig(
	'CA_CHANGE_SMS_INFO_SUCCESS',
	SMS.INFO,
	OPERATION_STATUSES.AVAIL
)
const changePackageCustomInsuranceLoadingConfig = getChangeReconfigureStatusConfig(
	'CA_CHANGE_PACKAGE_CUSTOM_INSURANCE_LOADING',
	INSURANCE.PACKAGE_CUSTOM,
	OPERATION_STATUSES.LOADING
)
const changePackageCustomInsuranceSuccessConfig = getChangeReconfigureStatusConfig(
	'CA_CHANGE_PACKAGE_CUSTOM_INSURANCE_SUCCESS',
	INSURANCE.PACKAGE_CUSTOM,
	OPERATION_STATUSES.AVAIL
)
const changeTravelInsuranceLoadingConfig = getChangeReconfigureStatusConfig(
	'CA_CHANGE_TRAVEL_INSURANCE_LOADING',
	INSURANCE.TRAVEL,
	OPERATION_STATUSES.LOADING
)
const changeTravelInsuranceSuccessConfig = getChangeReconfigureStatusConfig(
	'CA_CHANGE_TRAVEL_INSURANCE_SUCCESS',
	INSURANCE.TRAVEL,
	OPERATION_STATUSES.AVAIL
)
const changeFlightOnlineCheckinLoadingConfig = getChangeReconfigureStatusConfig(
	'CA_CHANGE_FLIGHT_ONLINE_CHECKIN_LOADING',
	EXTENDED_SERVICES.CHECK_IN,
	OPERATION_STATUSES.LOADING
)
const changeFlightOnlineCheckinSuccessConfig = getChangeReconfigureStatusConfig(
	'CA_CHANGE_FLIGHT_ONLINE_CHECKIN_SUCCESS',
	EXTENDED_SERVICES.CHECK_IN,
	OPERATION_STATUSES.AVAIL
)
const changePrioritySupportLoadingConfig = getChangeReconfigureStatusConfig(
	'CA_CHANGE_PRIORITY_SUPPORT_LOADING',
	EXTENDED_SERVICES.PRIORITY_SUPPORT,
	OPERATION_STATUSES.LOADING
)
const changePrioritySupportSuccessConfig = getChangeReconfigureStatusConfig(
	'CA_CHANGE_PRIORITY_SUPPORT_SUCCESS',
	EXTENDED_SERVICES.PRIORITY_SUPPORT,
	OPERATION_STATUSES.AVAIL
)
const onSmsTooltipClickConfig = {
	eventName: 'CA_ON_SMS_TOOLTIP_CLICK',
	pattern: ({ type, meta }) => (
		type === ON_ELEMENT_CLICK
		&& meta.elementKey === 'sms-info-tooltip'
	),
}
const onPackageCustomInsuranceTermsClickConfig = {
	eventName: 'CA_ON_PACKAGE_CUSTOM_INSURANCE_TERMS_CLICK',
	pattern: ({ type, meta }) => (
		type === ON_ELEMENT_CLICK
		&& meta.elementKey === `insurance-terms-${INSURANCE.PACKAGE_CUSTOM}`
	),
}
const onTravelInsuranceTermsClickConfig = {
	eventName: 'CA_ON_TRAVEL_INSURANCE_TERMS_CLICK',
	pattern: ({ type, meta }) => (
		type === ON_ELEMENT_CLICK
		&& meta.elementKey === `insurance-terms-${INSURANCE.TRAVEL}`
	),
}
const onEditRouteClickConfig = {
	eventName: 'CA_ON_EDIT_ROUTE_CLICK',
	pattern: ({ type, meta }) => (
		type === ON_ELEMENT_CLICK
		&& meta.elementKey === 'editRoute'
	),
}
const onTermsCheckoutClickConfig = {
	eventName: 'ON_TERMS_CHECKOUT_CLICK',
	pattern: ({ type, meta }) => (
		type === ON_ELEMENT_CLICK
		&& meta.elementKey === 'termsOnCheckout'
	),
}
const onVisaBaggageWarnClickConfig = {
	eventName: 'ON_VISA_BAGGAGE_WARN_CLICK',
	pattern: ({ type, meta }) => (
		type === ON_ELEMENT_CLICK
		&& meta.elementKey === 'visaBaggageWarn'
	),
}

const getOfferResolvedUnavailConfig = {
	eventName: 'CA_GET_OFFER_RESOLVED',
	pattern: ({ type, payload }) => (
		type === GET_OFFER_RESOLVED_WITH_STATUS
		&& payload === OPERATION_STATUSES.UNAVAIL
	),
}

const buyBuyBtnClickWithInvalidPassGenderConfig = {
	eventName: 'CA_BUY_BTN_CLICK_WITH_INVALID_PASS_GENDER',
	pattern: ({ type, meta }) => (
		type === BUY
			&& meta.isInitial
	),
	checkStateConditions: function* () {
		const errors = yield select(invalidFieldsSelector)
		return errors.length > 0 && every(errors, { formName: 'passengers', fieldName: 'gender' })
	},
}

const buyBuyBtnClickWithInvalidPassConfig = {
	eventName: 'CA_BUY_BTN_CLICK_WITH_INVALID_PASS',
	pattern: ({ type, meta }) => (
		type === BUY
			&& meta.isInitial
	),
	checkStateConditions: function* () {
		const errors = yield select(invalidFieldsSelector)
		return errors.length > 0 && every(errors, { formName: 'passengers' }) && !every(errors, { fieldName: 'gender' })
	},
}

const buyBuyBtnClickWithInvalidCardConfig = {
	eventName: 'CA_BUY_BTN_CLICK_WITH_INVALID_CARD',
	pattern: ({ type, meta }) => (
		type === BUY
			&& meta.isInitial
	),
	checkStateConditions: function* () {
		const errors = yield select(invalidFieldsSelector)
		return errors.length > 0 && every(errors, { formName: 'creditCard' })
	},
}

const buyBuyBtnClickWithInvalidConfig = {
	eventName: 'CA_BUY_BTN_CLICK_WITH_INVALID_FORMS',
	pattern: ({ type, meta }) => (
		type === BUY
			&& meta.isInitial
	),
	checkStateConditions: function* () {
		const errors = yield select(invalidFieldsSelector)
		return errors.length > 0
	},
}

const simpleEventConfigList = [
	buyBuyBtnClickWithInvalidConfig,
	buyBuyBtnClickWithInvalidCardConfig,
	buyBuyBtnClickWithInvalidPassConfig,
	buyBuyBtnClickWithInvalidPassGenderConfig,
	redirectToPartnerConfig,
	checkoutOpenedConfig,
	loadOfferSuccessConfig,
	checkOfferSuccessConfig,
	bookOrderConfig,
	ecChechoutStartConfig,
	loadOfferFailureConfig,
	addSmsInfoCheckboxConfig,
	addSmsInfoAdditionalServiceBlockConfig,
	addSmsInfoUpsaleDialogConfig,
	removeSmsInfoUpsaleDialogConfig,
	removeSmsInfoCheckboxConfig,
	removeSmsInfoAdditionalServiceBlockConfig,
	addPackageCustomInsuranceCheckboxConfig,
	addPackageCustomInsuranceUpsaleDialogConfig,
	addTravelInsuranceCheckboxConfig,
	addInabilityToTravelInsuranceCheckboxConfig,
	removePackageCustomInsuranceCheckboxConfig,
	removeTravelInsuranceCheckboxConfig,
	removeInabilityToTravelInsuranceCheckboxConfig,
	removePackageCustomInsuranceUpsaleDialogConfig,
	addInsuranceRadioBtnConfig,
	removeInsuranceRadbioBtnConfig,
	addFlightOnlineCheckinConfig,
	removeFlightOnlineCheckinConfig,
	addPrioritySupportConfig,
	removePrioritySupportConfig,
	changeConsistOnCheckoutConfig,
	acceptTermsClickConfig,
	paymentMethodConfig,
	buyBtnClickConfig,
	ecChechoutBuyStepConfig,
	ecChechoutBookStepConfig,
	bookSuccessConfig,
	ecPurchaseConfig,
	createOrderFailedConfig,
	bookFailedConfig,
	bookFailedNotCriticalConfig,
	redirectTo3DSConfig,
	checkoutOpenedFrom3dsConfig,
	priceChangedConfig,
	changeConsistOnCheckoutFailedConfig,
	changeConsistOnCheckoutSuccessConfig,
	transitionToSerpConfig,
	transitionToIndexConfig,
	changeFlightVariantConfig,
	changeFlightVariantLoadingConfig,
	changeFlightVariantSuccessConfig,
	changeSmsInfoLoadingConfig,
	changeSmsInfoSuccessConfig,
	changePackageCustomInsuranceLoadingConfig,
	changePackageCustomInsuranceSuccessConfig,
	changeTravelInsuranceLoadingConfig,
	changeTravelInsuranceSuccessConfig,
	changeFlightOnlineCheckinLoadingConfig,
	changeFlightOnlineCheckinSuccessConfig,
	changePrioritySupportLoadingConfig,
	changePrioritySupportSuccessConfig,
	onSmsTooltipClickConfig,
	onPackageCustomInsuranceTermsClickConfig,
	onTravelInsuranceTermsClickConfig,
	onEditRouteClickConfig,
	onTermsCheckoutClickConfig,
	onVisaBaggageWarnClickConfig,
	getOfferResolvedUnavailConfig,
]
const simpleEventListeners = simpleEventConfigList.map(getSimpleActionToEvent)

const setCustomerEmailConfig = {
	eventName: 'CA_SET_CUSTOMER_EMAIL_INPUT',
	pattern: ({ type, payload }) => (
		type === SET_FORM_FIELD_VALUE
		&& payload.formName === CUSTOMER_FORM.NAME
		&& payload.fieldName === CUSTOMER_FORM.FIELDS.EMAIL
	),
}
const setCustomerPhoneConfig = {
	eventName: 'CA_SET_CUSTOMER_PHONE_INPUT',
	pattern: ({ type, payload }) => (
		type === SET_FORM_FIELD_VALUE
		&& payload.formName === CUSTOMER_FORM.NAME
		&& payload.fieldName === CUSTOMER_FORM.FIELDS.PHONE
	),
}
const FIRST_PAX_FORM_INDEX = 'a0'
const setFirstPaxDataConfig = {
	eventName: 'CA_FIRST_PASSENGER_FILLED_IN',
	pattern: ({ type, payload }) => (
		type === SET_FORM_FIELD_VALUE
		&& payload.formName === PASSENGERS_FORM.NAME
		&& payload.formIndex === FIRST_PAX_FORM_INDEX
	),
	checkStateConditions: function* () {
		return yield select(passengerSelectors.isFormValidSelector, FIRST_PAX_FORM_INDEX)
	},
}

const getSetFirstPaxFieldConfig = (eventName, fieldPattern, isValidSelector) => ({
	eventName,
	pattern: ({ type, payload }) => {
		let [ formIndex, formName, fieldName ] = get(payload, 'target.dataset.focus', '//').split('/', 3)
		return type === HANDLE_BLUR_EVT
			&& formName === PASSENGERS_FORM.NAME
			&& fieldName === fieldPattern
			&& formIndex === FIRST_PAX_FORM_INDEX
	},
	checkStateConditions: function* () {
		return yield select(isValidSelector, FIRST_PAX_FORM_INDEX)
	},
})
const setFirstPaxLastnameConfig = getSetFirstPaxFieldConfig(
	'CA_FIRST_PASSENGER_LASTNAME_FILLED_IN',
	PASSENGERS_FORM.FIELDS.LAST_NAME,
	passengerSelectors.isLastNameValidSelector
)
const setFirstPaxFirstnameConfig = getSetFirstPaxFieldConfig(
	'CA_FIRST_PASSENGER_FIRSTNAME_FILLED_IN',
	PASSENGERS_FORM.FIELDS.FIRST_NAME,
	passengerSelectors.isFirstNameValidSelector
)
const setFirstPaxBirthdateConfig = getSetFirstPaxFieldConfig(
	'CA_FIRST_PASSENGER_BIRTHDATE_FILLED_IN',
	PASSENGERS_FORM.FIELDS.BIRTHDATE,
	passengerSelectors.isBirthdateValidSelector
)
const setFirstPaxGenderConfig = {
	eventName: 'CA_FIRST_PASSENGER_GENDER_CHANGE',
	pattern: ({ type, payload }) => (
		type === SET_FORM_FIELD_VALUE
			&& payload.formName === PASSENGERS_FORM.NAME
			&& payload.formIndex === FIRST_PAX_FORM_INDEX
			&& payload.fieldName === PASSENGERS_FORM.FIELDS.GENDER
	),
	checkStateConditions: function* () {
		return yield select(passengerSelectors.isGenderValidSelector, FIRST_PAX_FORM_INDEX)
	},
}
const setFirstPaxCitizenshipConfig = getSetFirstPaxFieldConfig(
	'CA_FIRST_PASSENGER_CITIZENSHIP_FILLED_IN',
	PASSENGERS_FORM.FIELDS.CITIZENSHIP,
	passengerSelectors.isCitizenshipValidSelector
)
const setFirstPaxDocumentNumConfig = getSetFirstPaxFieldConfig(
	'CA_FIRST_PASSENGER_DOCUMENT_NUM_FILLED_IN',
	PASSENGERS_FORM.FIELDS.DOCUMENT_NUM,
	passengerSelectors.isDocumentNumValidSelector
)
const setFirstPaxDocumentExpDateConfig = getSetFirstPaxFieldConfig(
	'CA_FIRST_PASSENGER_DOCUMENT_EXP_DATE_FILLED_IN',
	PASSENGERS_FORM.FIELDS.DOCUMENT_EXP_DATE,
	passengerSelectors.isDocumentExpDateValidSelector
)

const isBlurEvtOnField = (type, payload, formPattern, fieldPattern) => {
	let [ _, formName, fieldName ] = get(payload, 'target.dataset.focus', '//').split('/', 3)
	return type === HANDLE_BLUR_EVT
		&& formName === formPattern
		&& fieldName === fieldPattern
}

const isBlurEvtOnCardField = (type, payload, fieldPattern) => isBlurEvtOnField(
	type, payload, CREDIT_CARD_FORM.NAME, fieldPattern)

const setCreditCardConfig = {
	eventName: 'CA_CREDIT_CARD_FILLED_IN',
	pattern: ({ type, payload }) => {
		let [ _, formName, fieldName ] = get(payload, 'target.dataset.focus', '//').split('/', 3)
		return type === HANDLE_BLUR_EVT
			&& formName === CREDIT_CARD_FORM.NAME
			&& [
				CREDIT_CARD_FORM.FIELDS.PAN,
				CREDIT_CARD_FORM.FIELDS.EXP_DATE,
				CREDIT_CARD_FORM.FIELDS.CVV,
				CREDIT_CARD_FORM.FIELDS.CARD_HOLDER,
			].indexOf(fieldName) >= 0
	},
	checkStateConditions: function* () {
		return yield select(creditCardSelectors.isFormValidSelector)
	},
}

const setCreditCardPanConfig = {
	eventName: 'CA_CREDIT_CARD_PAN_FILLED_IN',
	pattern: ({ type, payload }) => isBlurEvtOnCardField(type, payload, CREDIT_CARD_FORM.FIELDS.PAN),
	checkStateConditions: function* () {
		return yield select(creditCardSelectors.isPanValidSelector)
	},
}

const setCreditCardExpDateConfig = {
	eventName: 'CA_CREDIT_CARD_EXP_DATE_FILLED_IN',
	pattern: ({ type, payload }) => isBlurEvtOnCardField(type, payload, CREDIT_CARD_FORM.FIELDS.EXP_DATE),
	checkStateConditions: function* () {
		return yield select(creditCardSelectors.isExpDateValidSelector)
	},
}

const setCreditCardCvvConfig = {
	eventName: 'CA_CREDIT_CARD_CVV_FILLED_IN',
	pattern: ({ type, payload }) => isBlurEvtOnCardField(type, payload, CREDIT_CARD_FORM.FIELDS.CVV),
	checkStateConditions: function* () {
		return yield select(creditCardSelectors.isCvvValidSelector)
	},
}

const isBlurEvtOnPassField = (type, payload, fieldPattern) => isBlurEvtOnField(
	type, payload, PASSENGERS_FORM.NAME, fieldPattern)

const checkInvalidFieldState = function* (payload, selector) {
	if (get(payload, 'target.value', '') === '') return false

	let [ idx ] = get(payload, 'target.dataset.focus', '//').split('/', 1)
	const isValid = yield select(selector, idx)
	return !isValid
}

const setInvalidLastNameConfig = {
	eventName: 'CA_SET_INVALID_LAST_NAME',
	pattern: ({ type, payload }) => isBlurEvtOnPassField(type, payload, PASSENGERS_FORM.FIELDS.LAST_NAME),
	checkStateConditions: ({ payload }) => checkInvalidFieldState(
		payload,
		passengerSelectors.isLastNameValidSelector,
	),
}

const setInvalidFirstNameConfig = {
	eventName: 'CA_SET_INVALID_FIRST_NAME',
	pattern: ({ type, payload }) => isBlurEvtOnPassField(type, payload, PASSENGERS_FORM.FIELDS.FIRST_NAME),
	checkStateConditions: ({ payload }) => checkInvalidFieldState(
		payload,
		passengerSelectors.isFirstNameValidSelector,
	),
}

const setInvalidBirthdateConfig = {
	eventName: 'CA_SET_INVALID_BIRTHDATE',
	pattern: ({ type, payload }) => isBlurEvtOnPassField(type, payload, PASSENGERS_FORM.FIELDS.BIRTHDATE),
	checkStateConditions: ({ payload }) => checkInvalidFieldState(
		payload,
		passengerSelectors.isBirthdateValidSelector,
	),
}

const setInvalidDocumentNumConfig = {
	eventName: 'CA_SET_INVALID_DOCUMENT_NUM',
	pattern: ({ type, payload }) => isBlurEvtOnPassField(type, payload, PASSENGERS_FORM.FIELDS.DOCUMENT_NUM),
	checkStateConditions: ({ payload }) => checkInvalidFieldState(
		payload,
		passengerSelectors.isDocumentNumValidSelector,
	),
}

const setInvalidDocumentExpDateConfig = {
	eventName: 'CA_SET_INVALID_DOCUMENT_EXP_DATE',
	pattern: ({ type, payload }) => isBlurEvtOnPassField(type, payload, PASSENGERS_FORM.FIELDS.DOCUMENT_EXP_DATE),
	checkStateConditions: ({ payload }) => checkInvalidFieldState(
		payload,
		passengerSelectors.isDocumentExpDateValidSelector,
	),
}

const isBlurEvtOnCreditCardField = (type, payload, fieldPattern) => isBlurEvtOnField(
	type, payload, CREDIT_CARD_FORM.NAME, fieldPattern)

const checkInvalidCreditCardFieldState = function* (payload, selector) {
	if (get(payload, 'target.value', '') === '') return false

	const isValid = yield select(selector)
	return !isValid
}

const setInvalidCreditCardPanConfig = {
	eventName: 'CA_INVALID_CREDIT_CARD_PAN',
	pattern: ({ type, payload }) => isBlurEvtOnCreditCardField(type, payload, CREDIT_CARD_FORM.FIELDS.PAN),
	checkStateConditions: ({ payload }) => checkInvalidCreditCardFieldState(
		payload,
		creditCardSelectors.isPanValidSelector,
	),
}

const setInvalidCreditCardExpDateConfig = {
	eventName: 'CA_INVALID_CREDIT_CARD_EXP_DATE',
	pattern: ({ type, payload }) => isBlurEvtOnCreditCardField(type, payload, CREDIT_CARD_FORM.FIELDS.EXP_DATE),
	checkStateConditions: ({ payload }) => checkInvalidCreditCardFieldState(
		payload,
		creditCardSelectors.isExpDateValidSelector,
	),
}

const setInvalidCreditCardCvvConfig = {
	eventName: 'CA_INVALID_CREDIT_CARD_CVV',
	pattern: ({ type, payload }) => isBlurEvtOnCreditCardField(type, payload, CREDIT_CARD_FORM.FIELDS.CVV),
	checkStateConditions: ({ payload }) => checkInvalidCreditCardFieldState(
		payload,
		creditCardSelectors.isCvvValidSelector,
	),
}

const formEventConfigList = [
	setCustomerEmailConfig,
	setCustomerPhoneConfig,
	setFirstPaxDataConfig,
	setCreditCardConfig,
	setCreditCardPanConfig,
	setCreditCardExpDateConfig,
	setCreditCardCvvConfig,
	setFirstPaxLastnameConfig,
	setFirstPaxFirstnameConfig,
	setFirstPaxBirthdateConfig,
	setFirstPaxGenderConfig,
	setFirstPaxCitizenshipConfig,
	setFirstPaxDocumentNumConfig,
	setFirstPaxDocumentExpDateConfig,
	setInvalidLastNameConfig,
	setInvalidFirstNameConfig,
	setInvalidBirthdateConfig,
	setInvalidDocumentNumConfig,
	setInvalidDocumentExpDateConfig,
	setInvalidCreditCardPanConfig,
	setInvalidCreditCardExpDateConfig,
	setInvalidCreditCardCvvConfig,
]
const formEventListeners = formEventConfigList.map(getFormFillActionToEvent)

const listeners = [
	...simpleEventListeners,
	...formEventListeners,
	flightFaresVariantsLoadedListener,
	startToFillpanListener,
]
export default function* checkoutListeners (analyticEventChan) {
	yield all(listeners.map((listener) => fork(listener, analyticEventChan)))
}
