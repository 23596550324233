import locale from '../switchers/locale.js'
import { LEGAL_ENTITIES } from '../switchers/legalEntity.js'


export default {
	[LEGAL_ENTITIES.TRAVELAB_AGENT]: locale({
		default: {
			Title: 'ООО «Тревелаб Агент»',
			LegalAdrText: '105523, г Москва, Щелковское шоссе д 100, корпус 3, офис 21',
			CheckAccountText: '40702810800000036853 в ПАО «Промсвязьбанк», Москва',
			CorrAccountText: '30101810400000000555 БИК: 044525555',
			InnNumber: '7708261531',
			KppNumber: '771901001',
			OKVED: 'ОКВЭД 63.30.3, 72.40, 72.30, 72.20, 72.10, 63.30.2, 63.30.',
			OKPO: 'ОКПО 29487069',
			OKATO: 'ОКАТО 45286565000',
			OGRN: 'ОГРН 1157746629435 выдано 13 июля 2015 года',
			OKTMO: 'ОКТМО 45378000000',
			OKOGU: 'ОКОГУ 4210014',
			OKFS: 'ОКФС 16',
			OKOPF: 'ОКОПФ 12300',
			DirectorName: 'Кольчиков Андрей Валерьевич',
			TaxSystemType: 'Упрощенная система налогообложения',
		},
	}),
	[LEGAL_ENTITIES.CLICKAVIA_TRAVEL]: locale({
		default: {
			Title: 'ООО «Клик Авиа Тревел»',
			LegalAdrText: '117279, г. Москва, ул. Миклухо-Маклая, д. 34, помещ. 37П',
			CheckAccountText: '40702810200000159479 в ПАО «Промсвязьбанк», Москва',
			CorrAccountText: '30101810400000000555 БИК: 044525555',
			InnNumber: '7728420229',
			KppNumber: '772801001',
			OKVED: 'ОКВЭД 79.90.1 79.90.3 79.11',
			OKPO: 'ОКПО 26539802',
			OKATO: 'ОКАТО 45293566000',
			OGRN: 'ОГРН 1187746239372 выдано 05 марта 2018 года',
			OKTMO: 'ОКТМО 45902000000',
			OKOGU: 'ОКОГУ 4210014',
			OKFS: 'ОКФС 16',
			OKOPF: 'ОКОПФ 12300',
			DirectorName: 'Сухачева Жанна Николаевна',
			TaxSystemType: 'Упрощенная система налогообложения',
		},
	}),
	[LEGAL_ENTITIES.FLY_CHARTERZ]: locale({
		default: {
			Title: 'ООО «Флай Чартерз»',
			LegalAdrText: '115054, г. Москва, ул. Дубининская, д.57, стр. 1, пом. 1, ком. 7б',
			CheckAccountText: '40702810710000229821 в АО «ТИНЬКОФФ БАНК» г. Москва',
			CorrAccountText: '30101810145250000974 БИК: 044525974',
			InnNumber: '7725355684',
			KppNumber: '772501001',
			OKVED: '',
			OKPO: '',
			OKATO: '',
			OGRN: 'ОГРН 1177746121662 выдано 09 февраля 2017 года',
			OKTMO: '',
			OKOGU: '',
			OKFS: '',
			OKOPF: '',
			DirectorName: 'Баранов Иван Игоревич',
			TaxSystemType: 'Упрощенная система налогообложения',
		},
	}),
	[LEGAL_ENTITIES.TRAVEL_GO]: locale({
		default: {
			foreign: true,
			RegisterNumber: '14439165',
			DateOfRegistration: '01/03/2018',
			Adress: '11415 Valukoja tn 8-110, Estonia, Tallin',
			IBAN: 'LT313500010003481969',
			AccountNumber: 'EVP9210003481969',
			SWIFT: 'EVIULT21XXX',
			BankCode: '35000',
			Bank: '"Paysera LT", UAB',
			BankAdress: 'Manoule (Ménulio) 7, Vilnius, LT-04326, Lithuania',
			Director: 'Ivan Baranov',

			Title: 'TravelGo OÜ',
			LegalAdrText: '11415, Estonia, Tallin, Valukoja tn 8-110',
			CheckAccountText: 'EVP9210003481969, "Paysera LT", UAB, Tallin',
			CorrAccountText: 'IBAN: LT313500010003481969, SWIFT: EVIULT21XXX',
			InnNumber: '14439165',
			KppNumber: '772501001',
			OKVED: '',
			OKPO: '',
			OKATO: '',
			OGRN: '01/03/2018',
			OKTMO: '',
			OKOGU: '',
			OKFS: '',
			OKOPF: '',
			DirectorName: 'Ivan Baranov',
			TaxSystemType: '',
		},
	}),
}
