export {
	OPERATION_STATUSES,
	ORDER_STATUSES,
	PAYMENT_STATUSES,
} from '@libs/feofan/statuses.js'

export const EVENT_TYPE = Object.freeze({
	LOADING_STARTED: 'LOADING_STARTED',
	DATA_CHANGED: 'DATA_CHANGED',
	LOADING_COMPLETED: 'LOADING_COMPLETED',
	DATA_EXPIRED: 'DATA_EXPIRED',
	SET_LOADING_EVENTS: 'SET_LOADING_EVENTS',
})

export const LOAD_TYPE = Object.freeze({
	OFFER: 'OFFER',
	CHECK_OFFER: 'CHECK_OFFER',
	RECONFIGURE_OFFER: 'RECONFIGURE_OFFER',
	REBUILD_OFFER: 'REBUILD_OFFER',
	BOOK: 'BOOK',
	ORDER: 'ORDER',
	ACCOUNT_DATA: 'ACCOUNT_DATA',
	HOTEL_EXT: 'HOTEL_EXT',
	SEARCH: 'SEARCH',
	TOP_PRICES: 'TOP_PRICES',
	PRICES_BY_MONTH: 'PRICES_BY_MONTH',
	PRICES_BY_DAY: 'PRICES_BY_DAY',
	GENERATE_SBP_PAYMENT: 'GENERATE_SBP_PAYMENT',
	GENERATE_TINKFOFF_PAYMENT: 'GENERATE_TINKFOFF_PAYMENT',
})

export const EXTENDED_SERVICES = Object.freeze({
	INSURANCE: 'insurance',
	SMS: 'sms',
	VISA: 'visa',
	ACCOMMODATION: 'accommodation',
	TRANSFER: 'transfer',
	TRANSFER_VARIANTS: 'transfer_variants',
	CHECK_IN: 'check-in',
	PRIORITY_SUPPORT: 'priority-support',
})

export const EXTENDED_SERVICES_SUBTYPE = Object.freeze({
	SMS: {
		INFO: 'sms-info',
		ARRIVAL: 'sms-arrival',
	},
	INSURANCE: {
		PACKAGE_CUSTOM: 'package_custom_1',
		TRAVEL: 'travel',
		INABILITY_TO_TRAVEL: 'inability_to_travel',
		CARD_THEFT: 'card_theft',
		TICKET_REFUND: 'ticket_refund',
		NCOV_NSP: 'ncov_nsp',
		NCOV_RETURN: 'ncov_return',
		NCOV_ACCIDENT_BUDGET: 'ncov_accident_budget',
		NCOV_ACCIDENT_LUXURY: 'ncov_accident_luxury',
	},
	VISA: {
		SINGLE: 'single',
		MULTI_TOURIST: 'multi-tourist',
		MULTI_REAL_ESTATE: 'multi-real-estate',
	},
	LOCAL_TRANSFER: {
		THERE: 'there',
		ROUND_TRIP: 'round-trip',
		BACK: 'back',
	},
})

export const ACCOUNT_DATA_STATUS = Object.freeze({
	INITIAL: 'ACCOUNT_DATA_STATUS/INITIAL',
	LOADING: 'ACCOUNT_DATA_STATUS/LOADING',
	SUCCESS: 'ACCOUNT_DATA_STATUS/SUCCESS',
	ERROR: 'ACCOUNT_DATA_STATUS/ERROR',
})

export const CONSIST_PTC = Object.freeze({
	ADULTS: 'ADT',
	CHILDREN: 'CHD',
	INFANTS: 'INF',
})

export const USER_ROLE = Object.freeze({
	AGENT: 'agent',
})

export const PRODUCT_TYPES = Object.freeze({
	ACCOMMODATIONS: 'accommodations',
	FLIGHTS: [
		'cross-hub-roundtrips',
		'cross-roundtrips',
		'cross-oneway-cross-roundtrips',
		'self-cross-roundtrips',
		'direct-roundtrips',
		'direct-oneways',
		'hub-roundtrips',
		'hub-oneways',
		'regular-hub-roundtrips',
		'regular-hub-oneways',
		'inconsist-oneways',
		'scheduled-cross-roundtrips',
	],
})

export const REDIRECT_TO_CABINET_STATUS = Object.freeze({
	NONE: 'NONE',
	SUCCESS: 'SUCCESS',
	FAILED: 'FAILED',
})
