import { get, first, last, values } from 'lodash'
import { createSelector } from 'reselect'
import { getDate } from '@utils/time-utils'
import {
	byCurrentOfferId,
	byCurrentSearchQuery,
	citiesSelector,
} from '@store/state/appState/selectors'
import {
	getBrands,
	airportsFromOfferSelector,
	offerByIdSelector,
	ticketsTypeFromOfferSelector,
	smsInfoFaresFromOfferSelector,
	smsArrivalFaresFromOfferSelector,
	checkInFaresFromOfferSelector,
	prioritySupportFaresFromOfferSelector,
	packageCustomInsFaresFromOfferSelector,
	travelInsFaresFromOfferSelector,
	inabilityToTravelInsFaresFromOfferSelector,
	antiTheftInsFaresFromOfferSelector,
	ticketRefundInsFaresFromOfferSelector,
	ncovNspInsFaresFromOfferSelector,
	ncovReturnInsFaresFromOfferSelector,
	ncovAccidentBudgetInsFaresFromOfferSelector,
	ncovAccidentLuxuryInsFaresFromOfferSelector,
	flightFaresFromOfferSelector,
	accommodationFaresFromOfferSelector,
	sortedSegmentsByFareIdSelector,
	hotelsByFareIdSelector,
	outboundFlightFromOfferSelector,
	inboundFlightFromOfferSelector,
} from '@store/state/domainData/selectors'
import { fakeTransferFareSelector } from '@organisms/FakeTransfer/selectors'


const sumReducer = (total, { amount, price }) => (total + (amount * price))

const getDetails = (brands) => (detailsType, fareCounts) => (fareData) => {
	const [ adults, children, infants ] = fareData.details.consist
	const FARE_COUNT_OFFSET = 1

	let subType = ''
	let amount = 1
	const brand = get(brands, fareData.supplier, {})
	const paymentType = get(fareData, 'payment_schedule.type', 'pre-pay')

	let meta = {
		paymentType,
		brandName: get(brand, 'legal_entity', ''),
	}

	let price = fareData.price.buyer
	if (detailsType !== 'flightTicket') {
		amount = fareCounts[fareData.id][FARE_COUNT_OFFSET]
		if (detailsType === 'hotel') {
			meta.hotelName = fareData.hotelName
		}
		else if (adults) subType = 'adults'
		else if (children) subType = 'children'
		else if (infants) subType = 'infants'
	}
	else {
		meta = { ...meta, ...fareData.route }
	}

	return {
		type: detailsType,
		subType,
		amount,
		price,
		meta,
	}
}

const getRoute = (segments, outFlight, inFlight) => {
	const outSegments = outFlight.filter(({ id }) => segments.some(({ id: segId }) => segId === id))
	const departSegment = first(outSegments)
	const depart = get(departSegment, 'depart.airport', '')
	const departTime = get(departSegment, 'depart.time', '')

	const arrive = get(last(outSegments), 'arrive.airport', '')
	const isRT = inFlight.length > 0

	return {
		depart,
		departTime: departTime ? getDate(departTime) : null,
		arrive,
		isRT,
	}
}

const getSorterByRoute = (outFlight, segmentsByFareIdMap) => (fareA, fareB) => {
	const segmentsA = segmentsByFareIdMap[fareA.id]
	const segmentsB = segmentsByFareIdMap[fareB.id]
	const outIdxA = outFlight.findIndex(({ id }) => segmentsA.find((seg) => seg.id === id))
	const outIdxB = outFlight.findIndex(({ id }) => segmentsB.find((seg) => seg.id === id))
	return outIdxA - outIdxB
}

const flightFaresWithRouteResult = (outFlight, inFlight, flightFares, airports, segmentsByFareIdMap, mophoMap) => (
	Object.values(flightFares).sort(getSorterByRoute(outFlight, segmentsByFareIdMap)).map((fare) => {
		const segments = segmentsByFareIdMap[fare.id]
		const { depart, departTime, arrive, isRT } = getRoute(segments, outFlight, inFlight)

		const departAirport = get(airports, depart, {})
		const departMetro = departAirport.metro || ''
		const departCity = departAirport['city_title'] || ''

		const arriveAirport = get(airports, arrive, {})
		const arriveMetro = arriveAirport.metro || ''
		const arriveCity = arriveAirport['city_title'] || ''

		return {
			...fare,
			route: {
				depart,
				departTime,
				departMorpho: departMetro ? mophoMap[departMetro] : {},
				departCity,
				arrive,
				arriveMorpho: arriveMetro ? mophoMap[arriveMetro] : {},
				arriveCity,
				isRT,
			},
		}
	})
)

const flightFaresWithRouteSelector = createSelector(
	byCurrentOfferId(outboundFlightFromOfferSelector),
	byCurrentOfferId(inboundFlightFromOfferSelector),
	byCurrentOfferId(flightFaresFromOfferSelector),
	byCurrentOfferId(airportsFromOfferSelector),
	byCurrentSearchQuery(sortedSegmentsByFareIdSelector),
	citiesSelector,
	flightFaresWithRouteResult
)

const accommodationFaresWithNameResult = (accommodationFares, hotelByFareIdMap) => (
	Object.values(accommodationFares).map((fare) => {
		const hotel = hotelByFareIdMap[fare.id]

		return {
			...fare,
			hotelName: get(hotel, 'ru.name'),
		}
	})
)

const accommodationFaresWithNameSelector = createSelector(
	byCurrentOfferId(accommodationFaresFromOfferSelector),
	byCurrentSearchQuery(hotelsByFareIdSelector),
	accommodationFaresWithNameResult
)

export const selector = (brands, { fare_counts: fareCounts }, ticketsType, ...faresSelectors) => {
	const [
		smsInfoFares,
		flightFaresWithRoute,
		packageCustom,
		travel,
		inabilityToTravel,
		cardTheft,
		ticketRefund,
		ncovNsp,
		ncovReturn,
		ncovAccidentBudget,
		ncovAccidentLuxury,
		accommodationFaresWithName,
		fakeTransfer,
		smsArrivalFares,
		checkInFares,
		prioritySupportFares,
	] = faresSelectors

	const getDetailsMapper = getDetails(brands)

	const flightTicketDetailsTmp = flightFaresWithRoute.map(getDetailsMapper('flightTicket'))
	let flightTicketDetails = flightTicketDetailsTmp
	if (ticketsType !== 'hub') {
		flightTicketDetails = [ flightTicketDetailsTmp.reduce((acc, ticket) => {
			const ret =  {
				...ticket,
				meta: {
					...acc.meta,
					...ticket.meta,
					depart: get(acc, 'meta.depart') || get(ticket, 'meta.depart'),
					departCity: get(acc, 'meta.departCity') || get(ticket, 'meta.departCity'),
					departMorpho: get(acc, 'meta.departMorpho') || get(ticket, 'meta.departMorpho'),
				},
				price: acc.price + ticket.price,
			}
			return ret
		}, { price: 0 }) ]
	}

	const customPackageInsDetails = values(packageCustom)
		.map(getDetailsMapper('customPackageInsurance', fareCounts))
	const travelInsDetails = values(travel)
		.map(getDetailsMapper('travelInsurance', fareCounts))
	const inabilityToTravelInsDetails = values(inabilityToTravel)
		.map(getDetailsMapper('inabilityToTravelInsurance', fareCounts))
	const cardTheftInsDetails = values(cardTheft)
		.map(getDetailsMapper('cardTheftInsurance', fareCounts))
	const ticketRefundInsDetails = values(ticketRefund)
		.map(getDetailsMapper('ticketRefundInsurance', fareCounts))
	const ncovNspInsDetails = values(ncovNsp)
		.map(getDetailsMapper('ncovNspInsurance', fareCounts))
	const ncovReturnInsDetails = values(ncovReturn)
		.map(getDetailsMapper('ncovReturnInsurance', fareCounts))
	const ncovAccidentBudgetInsDetails = values(ncovAccidentBudget)
		.map(getDetailsMapper('ncovAccidentBudgetInsurance', fareCounts))
	const ncovAccidentLuxuryInsDetails = values(ncovAccidentLuxury)
		.map(getDetailsMapper('ncovAccidentLuxuryInsurance', fareCounts))
	const smsInfoDetails = Object.values(smsInfoFares).map(getDetailsMapper('smsInfo', fareCounts))
	const smsArrivalDetails = Object.values(smsArrivalFares).map(getDetailsMapper('smsArrival', fareCounts))
	const checkInDetails = Object.values(checkInFares).map(getDetailsMapper('flightCheckIn', fareCounts))
	const prioritySupportDetails = Object.values(prioritySupportFares)
		.map(getDetailsMapper('prioritySupport', fareCounts))
	const hotelDetails = accommodationFaresWithName.map(getDetailsMapper('hotel', fareCounts))
	const transferDetails = getDetails(fakeTransfer.brands)('transfer', fakeTransfer.fareCounts)(fakeTransfer.fare)

	const details = [
		...flightTicketDetails,
		...customPackageInsDetails,
		...travelInsDetails,
		...inabilityToTravelInsDetails,
		...cardTheftInsDetails,
		...ticketRefundInsDetails,
		...ncovNspInsDetails,
		...ncovReturnInsDetails,
		...ncovAccidentBudgetInsDetails,
		...ncovAccidentLuxuryInsDetails,
		...smsInfoDetails,
		...smsArrivalDetails,
		...checkInDetails,
		...prioritySupportDetails,
		...hotelDetails,
		transferDetails,
	]

	const filteredDetails = details.filter(({ amount, price }) => (amount > 0 && price > 0))
	const totalPrice = filteredDetails.reduce(sumReducer, 0)

	const prePayItems = filteredDetails.filter(({ meta }) => (meta.paymentType === 'pre-pay'))
	const postPayItems = filteredDetails.filter(({ meta }) => (meta.paymentType === 'post-pay'))

	const prePaySubtotal = {
		type: 'prePaySubtotal',
		price: prePayItems.reduce(sumReducer, 0),
	}

	const postPaySubtotal = {
		type: 'postPaySubtotal',
		price: postPayItems.reduce(sumReducer, 0),
		items: postPayItems,
	}

	return [
		...filteredDetails,
		{ type: 'total', price: totalPrice },
		prePaySubtotal,
		postPaySubtotal,
	]
}

export default createSelector(
	byCurrentSearchQuery(getBrands),
	byCurrentOfferId(offerByIdSelector),
	byCurrentOfferId(ticketsTypeFromOfferSelector),
	byCurrentOfferId(smsInfoFaresFromOfferSelector),
	flightFaresWithRouteSelector,
	byCurrentOfferId(packageCustomInsFaresFromOfferSelector),
	byCurrentOfferId(travelInsFaresFromOfferSelector),
	byCurrentOfferId(inabilityToTravelInsFaresFromOfferSelector),
	byCurrentOfferId(antiTheftInsFaresFromOfferSelector),
	byCurrentOfferId(ticketRefundInsFaresFromOfferSelector),
	byCurrentOfferId(ncovNspInsFaresFromOfferSelector),
	byCurrentOfferId(ncovReturnInsFaresFromOfferSelector),
	byCurrentOfferId(ncovAccidentBudgetInsFaresFromOfferSelector),
	byCurrentOfferId(ncovAccidentLuxuryInsFaresFromOfferSelector),
	accommodationFaresWithNameSelector,
	fakeTransferFareSelector,
	byCurrentOfferId(smsArrivalFaresFromOfferSelector),
	byCurrentOfferId(checkInFaresFromOfferSelector),
	byCurrentOfferId(prioritySupportFaresFromOfferSelector),
	selector
)
